import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { Link, useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Some interesting blogs'
  
  };
  const router = useRouteMatch()

  const [posts, setposts] = useState([])
  useEffect(() => {
    const getPosts = async () => {
      try {
        const res = await axios.get("https://api.bunyodbeck.uz/blog/list")
        setposts(res.data.Items)
      } catch (e) {
        alert("Error in api")
        console.log("message" + e)
      }
    }
    getPosts();
  }, [])

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>

          {router.path != "/blogs" ? <SectionHeader data={sectionHeader} className="center-content" /> : ""}
          <div className={splitClasses}>
            {posts.length == 0 ? "Loading..."
              : posts.map((item, itemKey) => (
                <div key={`post-${itemKey}`} className="split-item">
                  <div className=" center-content-mobile " data-reveal-container=".split-item">
                    <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                      {item?.tag?.S}
                    </div>
                    <Link to={`/blog/${item?.blogid?.S}`}>
                      <h3 className="mt-0 mb-12">
                        {item?.title?.S}
                      </h3>
                      <p className="m-0">
                        {item?.shortDescription?.S}
                      </p>
                    </Link>
                  </div>
                  <div className={
                    classNames(
                      'split-item-image center-content-mobile reveal-from-bottom',
                      imageFill && 'split-item-image'
                    )}
                    data-reveal-container=".split-item">
                    <img
                      src={item?.mainImage ? item?.mainImage?.S : require('./../../assets/images/ecs3.png')}
                      alt="Features split 01"
                      width={100}
                      height={200} />
                  </div>
                </div>
              ))}




          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;