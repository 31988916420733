import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
const SingleBlog = (props) => {
    const routeParams = useParams()
    console.log(routeParams);
    // const blogs = [
    //     {
    //         id: 1,
    //         data: <div className="container-sm ">
    //             <div className="hero-content">
    //                 <h3 className="mt-10 mb-16 reveal-from-bottom" style={{marginTop:"100px"}}  data-reveal-delay="200"> Amazon Elastic Container Service (Amazon ECS)</h3>
    //             </div>
    //             <div>
    //                 <img src='/12.png' />
    //             </div>
    //             <br />
    //             <div>

    //                 <p>ECS is the AWS Docker container service that handles the orchestration and provisioning of Docker containers. This is a beginner level introduction to AWS ECS. I’ve seen some nightmare posts and some glowing reviews about the ECS service so I knew it was going to interesting to get my hands dirty and see what ECS was all about.</p>
    //                 <ul>
    //                     <li>Launch thousands of containers across the cloud using your preferred continuous integration and delivery (CI/CD) and automation tools.</li>
    //                 </ul>
    //                 <ul>
    //                     <li>Launch thousands of containers across the cloud using your preferred continuous integration and delivery (CI/CD) and automation tools.</li>
    //                 </ul>
    //                 <ul>
    //                     <li>Launch thousands of containers across the cloud using your preferred continuous integration and delivery (CI/CD) and automation tools.</li>
    //                 </ul>
    //                 <h5>  Summary of the ECS Terms</h5>
    //                 <p>
    //                     First we need to cover ECS terminology:
    //                 </p>
    //                 <ul>
    //                     <li>Task Definition — This a blueprint that describes how a docker container should launch. If you are already familiar with AWS, it is like a LaunchConfig except instead it is for a docker container instead of a instance. It contains settings like exposed port, docker image, cpu shares, memory requirement, command to run and environmental variables.</li>
    //                     <li>Task — This is a running container with the settings defined in the Task Definition. It can be thought of as an “instance” of a Task Definition.</li>
    //                     <li>Service — Defines long running tasks of the same Task Definition. This can be 1 running container or multiple running containers all using the same Task Definition.</li>
    //                     <li>Cluster — A logic group of EC2 instances. When an instance launches the ecs-agent software on the server registers the instance to an ECS Cluster. This is easily configurable by setting the ECS_CLUSTER variable in /etc/ecs/ecs.config described.</li>
    //                     <li>Container Instance — This is just an EC2 instance that is part of an ECS Cluster and has docker and the ecs-agent running on it.</li>
    //                 </ul>
    //                 <p>I remember when I first got introduced to the all the terms, I quickly got confused. AWS provides nice detailed diagrams to help explain the terms. Here is a simplified diagram to help visualize and explain the terms.</p>
    //             </div>

    //         </div>

    //     },
    //     {
    //         id: 2,
    //         data: <div className="container-sm ">
    //             <div className="hero-content">
    //                 <h3 className="mt-10 mb-16 reveal-from-bottom" style={{marginTop:"100px"}}  data-reveal-delay="200"> EC2 (Amazon EC2)</h3>
    //             </div>
    //             <div>
    //                 <img src='/12.png' />
    //             </div>
    //             <br />
    //             <div>

    //                 <p>ECS is the AWS Docker container service that handles the orchestration and provisioning of Docker containers. This is a beginner level introduction to AWS ECS. I’ve seen some nightmare posts and some glowing reviews about the ECS service so I knew it was going to interesting to get my hands dirty and see what ECS was all about.</p>
    //                 <ul>
    //                     <li>Launch thousands of containers across the cloud using your preferred continuous integration and delivery (CI/CD) and automation tools.</li>
    //                 </ul>
    //                 <ul>
    //                     <li>Launch thousands of containers across the cloud using your preferred continuous integration and delivery (CI/CD) and automation tools.</li>
    //                 </ul>
    //                 <ul>
    //                     <li>Launch thousands of containers across the cloud using your preferred continuous integration and delivery (CI/CD) and automation tools.</li>
    //                 </ul>
    //                 <h5>  Summary of the ECS Terms</h5>
    //                 <p>
    //                     First we need to cover ECS terminology:
    //                 </p>
    //                 <ul>
    //                     <li>Task Definition — This a blueprint that describes how a docker container should launch. If you are already familiar with AWS, it is like a LaunchConfig except instead it is for a docker container instead of a instance. It contains settings like exposed port, docker image, cpu shares, memory requirement, command to run and environmental variables.</li>
    //                     <li>Task — This is a running container with the settings defined in the Task Definition. It can be thought of as an “instance” of a Task Definition.</li>
    //                     <li>Service — Defines long running tasks of the same Task Definition. This can be 1 running container or multiple running containers all using the same Task Definition.</li>
    //                     <li>Cluster — A logic group of EC2 instances. When an instance launches the ecs-agent software on the server registers the instance to an ECS Cluster. This is easily configurable by setting the ECS_CLUSTER variable in /etc/ecs/ecs.config described.</li>
    //                     <li>Container Instance — This is just an EC2 instance that is part of an ECS Cluster and has docker and the ecs-agent running on it.</li>
    //                 </ul>
    //                 <p>I remember when I first got introduced to the all the terms, I quickly got confused. AWS provides nice detailed diagrams to help explain the terms. Here is a simplified diagram to help visualize and explain the terms.</p>
    //             </div>

    //         </div>

    //     },
    //     {
    //         id: 3,
    //         data: <div className="container-sm ">

    //             <div className="hero-content">
    //                 <h3 className="mt-10 mb-16 reveal-from-bottom" style={{marginTop:"100px"}} data-reveal-delay="200"> ELastik bean (Amazon EC2)</h3>
    //             </div>
    //             <div>
    //                 <img src='/12.png' />
    //             </div>
    //             <br />
    //             <div>

    //                 <p>ECS is the AWS Docker container service that handles the orchestration and provisioning of Docker containers. This is a beginner level introduction to AWS ECS. I’ve seen some nightmare posts and some glowing reviews about the ECS service so I knew it was going to interesting to get my hands dirty and see what ECS was all about.</p>
    //                 <ul>
    //                     <li>Launch thousands of containers across the cloud using your preferred continuous integration and delivery (CI/CD) and automation tools.</li>
    //                 </ul>
    //                 <ul>
    //                     <li>Launch thousands of containers across the cloud using your preferred continuous integration and delivery (CI/CD) and automation tools.</li>
    //                 </ul>
    //                 <ul>
    //                     <li>Launch thousands of containers across the cloud using your preferred continuous integration and delivery (CI/CD) and automation tools.</li>
    //                 </ul>
    //                 <h5>  Summary of the ECS Terms</h5>
    //                 <p>
    //                     First we need to cover ECS terminology:
    //                 </p>
    //                 <ul>
    //                     <li>Task Definition — This a blueprint that describes how a docker container should launch. If you are already familiar with AWS, it is like a LaunchConfig except instead it is for a docker container instead of a instance. It contains settings like exposed port, docker image, cpu shares, memory requirement, command to run and environmental variables.</li>
    //                     <li>Task — This is a running container with the settings defined in the Task Definition. It can be thought of as an “instance” of a Task Definition.</li>
    //                     <li>Service — Defines long running tasks of the same Task Definition. This can be 1 running container or multiple running containers all using the same Task Definition.</li>
    //                     <li>Cluster — A logic group of EC2 instances. When an instance launches the ecs-agent software on the server registers the instance to an ECS Cluster. This is easily configurable by setting the ECS_CLUSTER variable in /etc/ecs/ecs.config described.</li>
    //                     <li>Container Instance — This is just an EC2 instance that is part of an ECS Cluster and has docker and the ecs-agent running on it.</li>
    //                 </ul>
    //                 <p>I remember when I first got introduced to the all the terms, I quickly got confused. AWS provides nice detailed diagrams to help explain the terms. Here is a simplified diagram to help visualize and explain the terms.</p>
    //             </div>

    //         </div>

    //     }
    // ]
    const [blog, setBlog] = useState("")
    useEffect(() => {
        const getBlog = async () => {
            try {
                const res = await axios.get(`https://api.bunyodbeck.uz/blog/${routeParams.id}`)
                setBlog(res.data.Item)
            } catch (e) {
                alert("Error in api")
                console.log("message" + e)
            }
        }
        getBlog();
    }, [])
    return (
        <>
            {/* {
                blogs.find(i => i.id == routeParams.id).data
            } */}
            <div class="container-sm " style={{ position: "relative", top: "80px" }}>
                <div class="hero-content">
                    <h2>{blog?.title}</h2>
                </div>
                <div>
                    <img style={{display:'block', marginLeft:'auto', marginRight:'auto'}} width="450px" height="320px" src={blog?.mainImage} />
                </div>
                <div dangerouslySetInnerHTML={{ __html: blog ? blog?.content : "Loading..." }}>
                </div>
            </div>
        </>
    )

}
export default SingleBlog