import React from 'react';
import { SectionSplitProps } from '../utils/SectionProps';
import FeaturesSplit from '../components/sections/FeaturesSplit';

const Blogs = ()=> {
    
    return(  
             <div className="container-sm ">
             <div className="hero-content">
            <h3 className="mt-10 reveal-from-bottom" data-reveal-delay="200"> <span className="text-color-success">Blogs</span></h3>
            </div>
            

            <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02 p-0" />
   
            </div>
          
      
    )

}
export default Blogs