import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import axios from 'axios';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Contact = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  const initialData = {
    GuestName: '',
    MessageTitle: '',
    Email: '',
    Message: '',
    Phone: '',
  }
  const [sendedData, setSendedData] = useState(initialData)
  const changer = (e) => {
    setSendedData({ ...sendedData, [e.target.name]: e.target.value })
  }

  //-----
  const handleSubmit = async (e) => {
    e.preventDefault()
    const res = await fetch('https://mw6muu99t1.execute-api.us-east-1.amazonaws.com/v1/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sendedData),
    })
      .then((res) => {
        console.log(res)
        return res.json()
      })
      .then((res) => {    //res==""
        if (res.success) {
          alert('Your message has been sent successfully!')
          setSendedData({ ...initialData })
        } else {
          alert('Oops something went wrong!')
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }
  //------




  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
              Do you want to ask about something?
              </h3>
          </div>
          <form onSubmit={handleSubmit} className="cta-action">
            <h5>Send me a message</h5>
            <Input id="guestName"  type="text" label="name" name="GuestName" labelHidden hasIcon="right" placeholder="name" required={true} onChange={changer} value={sendedData.GuestName}>      
            <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
      
            </Input>
            <br/>
            <Input id="email" type="email" label="email" name="Email" labelHidden hasIcon="right" placeholder="email" required={true} onChange={changer} value={sendedData.Email}>
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
            </Input>
            <br/>
            <Input id="phone" type="text" label="subject" name="Phone" labelHidden hasIcon="right" placeholder="phone" onChange={changer} value={sendedData.Phone}>
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
            </Input>
            <br/>
            <Input id="messageTitle" type="text" label="subject" name="MessageTitle" labelHidden hasIcon="right" placeholder="subject" required={true} onChange={changer} value={sendedData.MessageTitle}>
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
            </Input>
            <br/>
            <Input id="message" type="textarea" label="message" name="Message" labelHidden hasIcon="right" placeholder="message" required={true} onChange={changer} value={sendedData.Message}>
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
            </Input>
            <br/>
            <Button className={"form-input"} >
                Submit
                {/* <button  id="newsletter"  type="submit">Send</button> */}
            </Button>
            
          </form>
        </div>
      </div>
    </section>
  );
}

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

export default Contact;